import React from 'react';
import './App.css';
import logo from './kkjancorp-logo.svg';
import car1 from '../src/assets/images/car1.jpg';
import car2 from '../src/assets/images/car2.jpg';
import car3 from '../src/assets/images/car3.jpg';
import car4 from '../src/assets/images/car4.jpg';
import car5 from '../src/assets/images/car5.jpg';
import car6 from '../src/assets/images/car6.jpg';
import location from '../src/assets/images/google-maps.png';
import mail from '../src/assets/images/gmail.png';
import outgoingcall from '../src/assets/images/phone-call.png';
import { Link as ScrollLink, animateScroll as scroll } from 'react-scroll';

function App() {
  const email = "kkjan5corporation@gmail.com";

  const handleEmailButtonClick = () => {
    // This will open the user's default email client with the pre-filled email address
    window.location.href = `mailto:${email}`;
  };
  return (
    <div class="container">
    <header class="d-flex flex-wrap justify-content-center py-3 mb-4 border-bottom">
      <a href="/" class="d-flex align-items-center mb-3 mb-md-0 me-md-auto link-body-emphasis text-decoration-none">
      <img class="bi me-2 img-fluid" width= "200" height="auto" src={logo} alt="KKJan5Corporation Logo" />
      </a>

      <ul class="nav nav-pills">
        <li class="nav-item"><a href="#" class="nav-link active" aria-current="page">Home</a></li>
        <li class="nav-item"><ScrollLink to="services-section" smooth={true} duration={400} class="nav-link">Our Services</ScrollLink></li>
        <li class="nav-item"><ScrollLink to="aboutus-section" smooth={true} duration={400} class="nav-link">About</ScrollLink></li>
        <li class="nav-item"><ScrollLink to="contact-section" smooth={true} duration={400} class="nav-link">Contact</ScrollLink></li>
      </ul>
      
    </header>
    <div class="px-4 py-5 my-5 text-center">
    <img class="d-block mx-auto mb-4 img-fluid" src={logo} alt="KKJan5Corporation Logo"></img>
    <h1 class="display-1 fw-bold text-body-emphasis">Automobile Dealer</h1>
    <div class="py-4 col-lg-12 mx-auto">
      <p class="lead mb-4 text-start fs-3">Discover a world of possibilities with our selection of pre-loved cars ready to embark on new adventures.
      Each vehicle is carefully inspected and certified, ensuring reliability and peace of mind. By exporting these cars, we give them a second chance to bring joy to drivers around the globe.</p>
      <div class="d-grid gap-2 d-sm-flex justify-content-sm-center">
        <button onClick={handleEmailButtonClick} type="button" class="btn btn-primary btn-lg px-4 gap-3">Contact Us</button>
      </div>
    </div>
  </div>
  <div class="container px-4 py-2" id="services-section">
    <h1 class="pb-2 border-bottom text-center">Our Services</h1>
    <div class="row g-4 py-5 row-cols-1 row-cols-lg-3">
      <div class="feature col">
        <h3 class="fs-2 text-body-emphasis">車の販売</h3>
        <p> </p>
        
      </div>
      <div class="feature col">
        <h3 class="fs-2 text-body-emphasis">車の部品販売</h3>
        <p> </p>
        
      </div>
      <div class="feature col">
        <h3 class="fs-2 text-body-emphasis">中古電化製品の販売</h3>
        <p> </p>
        
      </div>
    </div>
    <div class="row g-4 row-cols-1 row-cols-lg-3">
      <div class="feature col">
        <h3 class="fs-2 text-body-emphasis">オートバイの販売</h3>
        <p> </p>
        
      </div>
      <div class="feature col">
        <h3 class="fs-2 text-body-emphasis">自転車の販売</h3>
        <p> </p>
        
      </div>
      <div class="feature col">
        <h3 class="fs-2 text-body-emphasis">タイヤの販売</h3>
        <p> </p>
        
      </div>
    </div>
  </div>
  <div id="carouselExampleAutoplaying" class="carousel slide" data-bs-ride="carousel">
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img src={car1} class="d-block w-100" alt="car image 1"></img>
    </div>
    <div class="carousel-item">
      <img src={car2} class="d-block w-100" alt="car image 2"></img>
    </div>
    <div class="carousel-item">
      <img src={car3} class="d-block w-100" alt="car image 3"></img>
    </div>
    <div class="carousel-item ">
      <img src={car4} class="d-block w-100" alt="car image 4"></img>
    </div>
    <div class="carousel-item">
      <img src={car5} class="d-block w-100" alt="car image 5"></img>
    </div>
    <div class="carousel-item">
      <img src={car6} class="d-block w-100" alt="car image 6"></img>
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>
<div class="container px-4 py-2" id="aboutus-section">
    <h1 class="pb-2 border-bottom text-center">About Us</h1>
    <p class="lead mb-4 text-start fs-3">Rev up your dreams!  We import your dream cars from japan to pakistan and hit the roads in style</p>
    <h2 class="pb-2  text-center">We are located in</h2>
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3224.845936077559!2d139.74497069999998!3d36.0728617!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6018b71a58df4613%3A0xf081ee0afded7ac9!2s1416-1%20Shinmeiuchi%2C%20Satte%2C%20Saitama%20340-0122%2C%20Japan!5e0!3m2!1sen!2s!4v1695539530292!5m2!1sen!2s" width="100%" height="auto"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
  </div>
<div class="container">
  <footer class="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top" id='contact-section'>
    <p class="col-md-4 mb-0 text-body-secondary">&copy; 2023 kkjan5, corp</p>

    <a href="/" class="col-md-4 d-flex align-items-center justify-content-center mb-3 mb-md-0 me-md-auto link-body-emphasis text-decoration-none">
    <img class="img-fluid" width= "100" height="auto" src={logo} alt="KKJan5Corporation Logo" />
    </a>

    <ul class="nav col-md-4 justify-content-end">
      <li class="nav-item"><a href="https://maps.app.goo.gl/PGGYD1A3zd2dRmeMA" class="nav-link px-2 text-body-secondary"><img width="30" src={location} alt="Location Icon" />幸手市 大字神明内 1416-1, Saitama Japan 340-0122</a></li>
      <li class="nav-item"><a href="tel:+819017300032" class="nav-link px-2 text-body-secondary"><img class="me-2" width="25" src={outgoingcall} alt="Phone Icon" />090-1730-0032</a></li>
      <li class="nav-item"><a href="mailto:kkjan5corporation@gmail.com" class="nav-link px-2 text-body-secondary"><img class="me-2" width="25" src={mail} alt="Mail Icon" />kkjan5corporation@gmail.com</a></li>
    </ul>
  </footer>
</div>
  </div>
  );
}

export default App;

